<template>
	<v-container
		id="Bonus_Configs"
		fluid
		tag="section">
		<v-row dense>
			<v-col>
				<v-card class="v-card--material pa-3">
					<card-heading
						:color="'secondary'"
						:title="'Bonus Configs'">
						<EditJokerBonusConfig
							:editJokerBonusConfigData="addConfig"
							@submit="save"
							:cleaning="false"
							v-if="
								_usrFlagsSome({
									key: 'bonus.bonus_config.detail',
									val: permissions.CREATE,
								})
							">
							<template v-slot:button="{ on, attrs }">
								<v-btn
									color="primary"
									elevation="1"
									fab
									small
									v-bind="attrs"
									v-on="on">
									<v-icon>mdi-plus</v-icon>
								</v-btn>
							</template>
						</EditJokerBonusConfig>
					</card-heading>
					<v-data-table
						:headers="filteredHeaders"
						:items="list"
						:options.sync="options"
						:server-items-length="total_records"
						:item-class="itemClass"
						:loading="loading"
						:footer-props="footerProps"
						class="elevation-1">
						<template #top="{ pagination }">
							<v-data-footer
								class="anton"
								:options.sync="options"
								v-bind="footerProps"
								:pagination="pagination"></v-data-footer>
						</template>
						<template #[`item.name`]="{ item }">
							{{ item.name }}
							<v-chip
								class="ml-3"
								v-if="
									item.deleted &&
									_usrFlagsSome({
										key: 'bonus.bonus_config.list',
										val: permissions.DELETE,
									})
								"
								small
								color="error">
								Deleted
							</v-chip>
						</template>
						<template v-slot:[`item.deleted`]="{ item }">
							{{ item.deleted | Date }}
						</template>
						<template v-slot:[`item.actions`]="{ item }">
							<v-tooltip
								color="primary darken-2"
								bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-icon
										color="primary"
										size="22"
										v-bind="attrs"
										v-on="on"
										class="mr-2"
										@click="onEditItem(item)"
										v-if="
											_usrFlagsSome({
												key: 'bonus.bonus_config.detail',
												val: permissions.READ,
											})
										">
										visibility
									</v-icon>
								</template>
								<span>Detail</span>
							</v-tooltip>

							<v-tooltip
								color="primary darken-2"
								bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-icon
										color="primary"
										size="24"
										v-bind="attrs"
										v-on="on"
										class="mr-2"
										@click="exportConfig(item)"
										v-if="
											_usrFlagsSome({
												key: 'bonus.bonus_config.detail',
												val: permissions.READ,
											})
										">
										mdi-arrow-down-bold
									</v-icon>
								</template>
								<span>Export</span>
							</v-tooltip>

							<v-tooltip
								color="primary darken-2"
								bottom
								class="mr-2"
								:key="`${item.id}-${item.deleted}-deleteBtn`">
								<template v-slot:activator="{ on, attrs }">
									<ConfirmDialog2
										persistent
										:confirmBtnText="'Delete'"
										:cancelBtnText="'Back'"
										:confirmBtnColor="'error'"
										:titleClass="'error'"
										:cancelBtnColor="'kajot-text'"
										:shouldShow="true"
										v-if="
											item.deleted == null &&
											_usrFlagsSome({
												key: 'bonus.bonus_config.detail',
												val: permissions.DELETE,
											})
										"
										@confirm="onDeleteItem(item.id)">
										<template v-slot:body>
											This Bonus Config will be deleted.
											<br />
											<b>Do you want to proceed?</b>
										</template>
										<template v-slot:default="{ showConfirm }">
											<v-icon
												color="primary"
												size="22"
												v-bind="attrs"
												v-on="on"
												@click="showConfirm">
												mdi-delete
											</v-icon>
										</template>
									</ConfirmDialog2>
								</template>
								<span>Delete</span>
							</v-tooltip>

							<v-tooltip
								color="primary darken-2"
								bottom
								:key="`${item.id}-${item.deleted}-restoreBtn`">
								<template v-slot:activator="{ on, attrs }">
									<v-icon
										color="primary"
										size="22"
										v-bind="attrs"
										v-on="on"
										v-if="
											item.deleted &&
											_usrFlagsSome({
												key: 'bonus.bonus_config.detail',
												val: permissions.DELETE,
											})
										"
										@click="onRestoreItem(item.id)">
										mdi-restore
									</v-icon>
								</template>
								<span>Restore</span>
							</v-tooltip>
						</template>
					</v-data-table>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import EditJokerBonusConfig from "../../components/jackpots/jackpot/EditJokerBonusConfig.vue";
import CardHeading from "../../components/shared/CardHeading.vue";
import ConfirmDialog2 from "../../components/shared/ConfirmDialog2.vue";
import table from "../../mixins/table";
import permissions from "../../mixins/permissions";
import { saveAsFile } from "../../constants/helpers";

export default {
	components: {
		EditJokerBonusConfig,
		CardHeading,
		ConfirmDialog2,
	},
	mixins: [permissions, table],
	data() {
		return {
			rowIsClickable: false,
			headers: [
				{ text: "id", value: "id", align: "center" },
				{ text: "Name", value: "name", align: "left" },
				{
					text: "Deleted",
					value: "deleted",
					align: "center",
					hide: () =>
						!this._usrFlagsSome({
							key: "bonus.bonus_config.list",
							val: this.permissions.DELETE,
						}),
				},
				{
					text: "Actions",
					value: "actions",
					sortable: false,
					align: "center",
					hide: () =>
						!this._usrFlagsSome({
							key: "bonus.bonus_config.detail",
							val: this.permissions.READ | this.permissions.DELETE,
						}),
				},
			],
			addConfig: { id: -1 },
		};
	},

	computed: {
		...mapGetters("jackpotConfig2", {
			list: "list",
		}),
	},

	methods: {
		...mapActions("jackpotConfig2", {
			_loadList: "loadList",
			deleteItem: "delete",
			restoreItem: "unDelete",
			createItem: "create",
		}),
		...mapMutations("jackpotConfig2", {
			setPagination: "pagination",
		}),
		saveAsFile,
		exportConfig(item) {
			const { created, updated, deleted, id, name, ...tmpExport } = item;
			this.saveAsFile(tmpExport, name);
		},
		loadList() {
			return this._loadList(
				!this._usrFlagsSome({
					key: "bonus.bonus_config.list",
					val: this.permissions.DELETE,
				})
					? { payload: { deleted: { eq: null } } }
					: undefined
			);
		},
		onEditItem(item) {
			this.$router.push({
				name: "bonus-config-detail",
				params: {
					bonus_config_id: item.id,
					bonus_config_name: item.name,
					breadcrumbs: { title: item.name },
				},
			});
		},
		async onDeleteItem(id) {
			await this.deleteItem(id);
			this._loadTable();
		},
		async onRestoreItem(id) {
			await this.restoreItem(id);
			this._loadTable();
		},
		async save(newConfig) {
			try {
				await this.createItem(newConfig);
				this._loadTable();
				this.addConfig = { id: -1 };
			} catch (error) {
				console.error(" ERROR : PATCH JOKER BONUS :", error);
			}
		},
	},
};
</script>
